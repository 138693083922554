<template>
  <div class="maxHeight">
    <div class="header flex">
      <div class="title flex">
        <img src="../../../assets/img/index_a.png" alt="" width="28">
        <h2>实验详情</h2>
      </div>
      <el-button size="small" @click="$router.go(-1)">返回上一页</el-button>
    </div>
    <div class="activeInfo">
      <span style="font-weight: bolder">实验编号：{{ activeInfo.number }}</span>
      <span v-if="activeInfo.detail">负责人：{{ activeInfo.dirNickname }}</span>
      <span>计划开始时间：{{ activeInfo.startTime | formatDay }}</span>
      <!--      <span>计划实验周期：{{ activeInfo.period }}周</span>-->
      <span> 计划结束时间：{{ activeInfo.period  | formatDay }}</span>
      <span>实验名称：{{ activeInfo.name }}</span>
    </div>
    <div class="maxWidth">
      <div class="step commonClass">
        <!--      -->
        <template v-for="(item,ind) in stepList">
          <el-button
              :disabled="item.disabled"
              type="text" size="small" class="stepList" :key="ind"
              @click="active=ind"
              v-if="(activeInfo.isLabAni==0&&(ind == 2 || ind == 3 || ind == 4))|| (4<ind||ind==1||ind==0)">
            <span class="tit" :class="[{activeClass:active == ind},{beforeClass:ind<active }]">{{ item.name }}</span>
            <i class="el-icon-arrow-down" v-show="ind!==stepList.length-1"></i>
          </el-button>
        </template>
      </div>
      <!--    实验方案-->
      <plan v-if="active==0" :id="id"></plan>
      <!--        方案审批-->
      <approval v-else-if="active==1" :id="id"></approval>
      <!--    采购-->
      <purchase v-else-if="active==2" :id="id" :status="status"></purchase>

      <!--    接收-->
      <receive v-else-if="active==3&&flag == true" :id="id" @changeStatus="changeStatus"
               :status='status'></receive>
      <!--    导出预览-->
      <receivePreview v-else-if="active==3&&flag == false" :id="id" @changeStatus="changeStatus"></receivePreview>

      <!--    检疫-->
      <quarantine v-else-if="active==4&&flagQ == true" :id="id" :status='status'
                  @changeStatus="changeStatus"></quarantine>
      <!--    导出预览-->
      <quarantineFinish v-else-if="active==4&&flagQ == false" :id="id" @changeStatus="changeStatus"></quarantineFinish>

      <!--    申领-->
      <claims v-else-if="active==5" :id="id" :activeInfo="activeInfo.aniOrder" :status="status"></claims>

      <!--    分笼-->
      <point v-else-if="active==6&&flagP == true" :id="id"></point>
      <!--    分笼完成-->
      <pointFinish v-else-if="active==6&&flagP == false" :id="id"></pointFinish>

      <!--    悬液配置-->
      <modeling v-else-if="active==7" :id="id" :status="status"></modeling>

      <!--      建模接种-->
      <vaccination v-else-if="active==8&&flagVacc==true" :id="id"></vaccination>
      <!--      接种中或者配置完成-->
      <vaccinationEnd v-else-if="active==8&&flagVacc==false&&vaccEnd==true" :id="id" :status="status"
                      @changePageGroup="changePageGroup"></vaccinationEnd>
      <!--      接种复核-->
      <vaccinationCheck v-else-if="active==8&&vaccEnd==false" :id="id"
                        @changePageGroup="changePageGroup"></vaccinationCheck>

      <!--    药前记录-->
      <!--    记录列表-->
      <medicineList v-else-if="active==9&&flagM == 1" @changePage="changePage" :id="id" :status="status"></medicineList>
      <!--    新增编辑-->
      <medicineBefore v-else-if="active==9&&flagM == 2" @changePage="changePage" :id="id"
                      :infoId="infoId"></medicineBefore>
      <!--    药前记录复核-->
      <medicineEnd v-else-if="active==9&&flagM == 3" @changePage="changePage" :id="id" :infoId="infoId"></medicineEnd>

      <!--    实验分组-->
      <grouping v-else-if="active==10&&flagGroup==true" :id="id" :status="status"
                @changePageGroup="changePageGroup"></grouping>
      <!--      实验分组复核-->
      <groupingEnd v-else-if="active==10&&flagGroup==false" @changePageGroup="changePageGroup" :id="id"></groupingEnd>

      <!--    供试品配制-->
      <preparation v-else-if="active==11&&flagPre==true" :id="id" @changePageGroup="changePageGroup"
                   :status="status"></preparation>
      <!--      供试品配制复核-->
      <preparationEnd v-else-if="active==11&&flagPre==false" @changePageGroup="changePageGroup"
                      :id="id"></preparationEnd>

      <!--    给药记录-->
      <!--      列表-->
      <drugList v-else-if="active==12&&drugDia ==1" @changePage="changePage" :id="id" :status="status"></drugList>
      <!--      新增编辑-->
      <drugDel v-else-if="active==12&&drugDia ==2" @changePage="changePage" :id="id" :drugId="drugId"></drugDel>
      <!--      复核-->
      <drugEnd v-else-if="active==12&&drugDia ==3" @changePage="changePage" :drugId="drugId" :id="id"></drugEnd>


      <!--    测量记录-->
      <!--      列表-->
      <measurementList v-else-if="active==13&&measureDia == 1" @changePage="changePage" :id="id"
                       :status="status"></measurementList>
      <!--      新增编辑-->
      <measurement v-else-if="active==13&&measureDia == 2" @changePage="changePage" :id="id"
                   :measureId="measureId"></measurement>
      <!--      复核-->
      <measurementEnd v-else-if="active==13&&measureDia == 3" @changePage="changePage" :id="id"
                      :measureId="measureId"></measurementEnd>

      <!--    观察记录-->
      <observeList v-else-if="active==14&&observeDia == 1" @changePage="changePage" :id="id"
                   :status="status"></observeList>
      <observe v-else-if="active==14&&observeDia == 2" @changePage="changePage" :id="id"
               :observeId="observeId"></observe>
      <observation v-else-if="active==14&&observeDia == 3" @changePage="changePage" :id="id"
                   :observeId="observeId"></observation>

      <!--    终点-->
      <record v-else-if="active==15&&endDia==true" @changePageGroup="changePageGroup" :id="id"></record>

      <recordEnd v-else-if="active==15&&endDia==false" @changePageGroup="changePageGroup" :id="id"
                 :status="status"></recordEnd>

      <!--    实验报告-->
      <report v-else :id="id"></report>
    </div>

    <div style="clear: both"></div>
  </div>

</template>

<script>
import {mapState} from "vuex";

export default {
  name: "detail",
  data() {
    return {
      activeInfo: {},
      status: null,
      id: this.$route.query.id,
      stepList: [
        {disabled: true, name: '实验方案'},
        {disabled: true, name: '方案审批'},
        {disabled: true, name: '动物请购'},
        {disabled: true, name: '动物接收'},
        {disabled: true, name: '动物检疫'},
        {disabled: true, name: '动物申领'},
        {disabled: true, name: '动物分笼'},
        {disabled: true, name: '配制'},
        {disabled: true, name: '建模接种'},
        {disabled: true, name: '药前记录'},
        {disabled: true, name: '实验分组'},
        {disabled: true, name: '供试品配制'},
        {disabled: true, name: '给药记录'},
        {disabled: true, name: '测量记录'},
        {disabled: true, name: '观察记录'},
        {disabled: true, name: '实验终点'},
        {disabled: true, name: '实验报告'},
      ],
      active: 0,
      // 接收
      flag: true,
      // 检疫
      flagQ: true,
      disabled: false,
      // 分笼
      flagP: false,
      // 药前
      flagM: 1,
      infoId: null,
      // 实验分组
      flagGroup: true,
      // 供试品
      flagPre: true,
      // 建模接种
      flagVacc: true,
      vaccEnd: true,
      // 给药
      drugDia: 1,
      drugId: null,
      // 测量
      measureDia: 1,
      measureId: null,
      // 观察
      observeDia: 1,
      observeId: null,
      //终点
      endDia: true,
    }
  },
  computed: {
    ...mapState({
      user: state => state.account.user
    })
  },
  mounted() {
    this.getDetail()
  },
  components: {
    // 实验详情
    "plan": () => import("./process/plan"),
    // 审批
    "approval": () => import("./process/approval"),
    // 请购
    "purchase": () => import("./process/purchase"),
    // 接收
    "receive": () => import("./process/receive"),
    // 接收预览
    "receivePreview": () => import("./mode/receivePreview"),
    // 检疫
    "quarantine": () => import("./process/quarantine"),
    // 检疫完成
    "quarantineFinish": () => import("./mode/quarantineFinish"),
    // 申领
    "claims": () => import("./process/claims"),
    // 分笼
    "point": () => import("./process/point"),
    // 实验报告
    "report": () => import("./process/report"),
    // 细胞悬液配置
    "modeling": () => import("./process/modeling"),
    // 药前记录--新增编辑
    "medicineBefore": () => import("./process/medicineBefore"),
    // 实验分组
    "grouping": () => import("./process/grouping"),
    // 供试品配制
    "preparation": () => import("./process/preparation"),
    // 给药记录--新增编辑
    "drugDel": () => import("./process/drugDel"),
    // 观察
    "observe": () => import("./process/observe"),
    // 终点
    "end": () => import("./process/end"),
    // 测量记录
    "measurement": () => import("./process/measurement"),
    // 分笼完成
    "pointFinish": () => import("./mode/pointFinish"),
    // 药前记录 复核+完成
    "medicineEnd": () => import("./mode/medicineEnd"),
    // 实验分组 复核+完成
    "groupingEnd": () => import("./mode/groupingEnd"),
    // 实验终点 复核+完成
    "recordEnd": () => import("./mode/recordEnd"),
    // 观察完成 复核+完成
    "observation": () => import("./mode/observation"),
    // 测量记录 复核+完成
    "measurementEnd": () => import("./mode/measurementEnd"),
    // 给药 复核+完成
    "drugEnd": () => import("./mode/drugEnd"),
    // 供试品配置 复核+完成
    "preparationEnd": () => import("./mode/preparationEnd"),
    // 药前记录 --列表
    "medicineList": () => import("./process/medicineList"),
    // 去接种
    "vaccination": () => import("./process/vaccination"),
    // 接种中
    "vaccinationEnd": () => import("./process/vaccinationEnd"),
    // 接种复核
    "vaccinationCheck": () => import("./process/vaccinationCheck"),
    // 给药记录--列表
    "drugList": () => import("./process/drugList"),
    // 测量记录--列表
    "measurementList": () => import("./process/measurementList"),
    // 观察记录--列表
    "observeList": () => import("./process/observeList"),
    // 实验终点--列表
    "record": () => import("./mode/record"),
  },
  methods: {
    getDetail() {
      let that = this;
      that.$get("/subject/detail/" + this.id).then((res) => {
        if (res.status == 200) {
          that.activeInfo = res.data
          that.status = res.data.status
          if (this.status == 0 || this.status == 1 || this.status == 2) {
            this.active = 0
          } else if (this.status == 3 || this.status == 4) {
            this.active = 1
          } else if (this.status == 5 || this.status == 6) {
            this.active = 2
          } else if (this.status == 8 || this.status == 9) {
            this.active = 3
          } else if (this.status == 10 || this.status == 11 || this.status == 12 || this.status == 14) {
            this.active = 4
          } else if (this.status == 15 || this.status == 16) {
            this.active = 5
          } else if (this.status == 17) {
            this.flagP = true
            this.active = 6
          } else if (this.status == 18) {
            this.active = 6
          } else if (this.status == 19 || this.status == 20 || this.status == 21) {
            this.active = 7
          } else if (this.status == 22) {
            this.active = 8
          } else if (this.status == 43 || this.status == 44 || this.status == 46) {
            this.flagVacc = false
            this.active = 8
          } else if (this.status == 23 || this.status == 24) {
            this.active = 9
          } else if (this.status == 25 || this.status == 26 || this.status == 27) {
            this.active = 10
          } else if (this.status == 28 || this.status == 29) {
            this.active = 11
          } else if (this.status == 41 || this.status == 42) {
            this.active = 11
          } else if (this.status == 50) {
            this.active = 12
          } else if (this.status == 51) {
            this.active = 12
            this.stepList.forEach(item => {
              if (item.name == '给药记录' || item.name == '测量记录' || item.name == '观察记录') {
                item.disabled = false
              }
            })
          } else if (this.status == 52 || this.status == 55 || this.status == 56 || this.status == 57) {
            this.active = 15
          } else if (this.status == 58) {
            this.active = 16
          }
          // 接种
          if (this.status == 44 || this.status == 46 || this.active > 8) {
            this.getCheckVacc()
          }
          // 供试品
          if (this.status >= 41 && this.active >= 11) {
            this.getCheckPre()
          }
          if (this.status >= 56 && this.active >= 15) {
            this.getCheckEnd()
          }

          this.stepList.forEach((item, index) => {
            if (this.active >= index) {
              item.disabled = false
            }
          })
        }
      }).catch(() => {
        this.$message.error('获取数据失败')
      })
    },
    // 切换接收检疫
    changeStatus(data, state) {
      if (state == 0) {
        this.flag = data
      } else {
        this.flagQ = data
      }
    },

    changePage(show, val, isPage) {
      // 切换药前记录页面
      if (isPage == 0) {
        this.flagM = show
        this.infoId = val
      } else if (isPage == 1) {
        // 切换给药
        this.drugDia = show
        this.drugId = val
      } else if (isPage == 2) {
        // 切换测量
        this.measureDia = show
        this.measureId = val
      } else if (isPage == 3) {
        // 切换测量
        this.observeDia = show
        this.observeId = val
      }
    },
    // 切换实验分组 供试品
    changePageGroup(show, ind) {
      if (ind == 0) {
        this.flagGroup = show
      } else if (ind == 1) {
        this.flagPre = show
      } else if (ind == 2) {
        this.vaccEnd = show
      } else if (ind == 3) {
        // 切换终点
        this.endDia = show
      }
    },
    // 获取供试品配置复核人
    getCheckPre() {
      this.$get('/solution/compound/' + this.id).then(res => {
        if (res.status == 200) {
          if (this.user.userId == res.data.reviewer) {
            this.flagPre = false
          }
        }
      })
    },
    // 获取接种人
    getCheckVacc() {
      this.$get('/model/vacc/' + this.id).then(res => {
        if (res.status == 200) {
          this.flagVacc = false
          if (res.data.modelVacc && this.user.userId == res.data.modelVacc.reviewer) {
            this.vaccEnd = false
          }
        }
      })
    },
    //获取实验终点复核人
    getCheckEnd() {
      this.$get('/subject/end/' + this.id).then(res => {
        if (res.status == 200) {
          if (this.user.userId == res.data.end.reviewer) {
            this.endDia = false
          }
        }
      })
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../../../assets/css/exper/experiDetail";
</style>
